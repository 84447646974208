import React from "react";
import classnames from "classnames";

import Placeholder, { Rect } from "components/Placeholder";

const DispensaryCardPlaceholder: React.FC<{
  /**
   * Whether to show an accessory tray, replaces the CTA button
   */
  accessoryTray?: boolean;
  /**
   * Optional className to apply to the card container
   */
  className?: string;
  /**
   * Minimizes certain parts of the card height, e.g. for Finder map view carousel
   */
  compact?: boolean;
  /**
   * Whether to always hide the CTA button
   */
  hideCTA?: boolean;
  /**
   * A unique identifier - required if using in a SSR rendered component.
   */
  id: string;
  /**
   * Boolean value to set a min-height style on some contents of
   * the card to keep them inline even if missing
   */
  preserveHeight?: boolean;
  /**
   * Which variant of the card to display, defaults to standard
   */
  variant?: "platinum" | "standard" | "info";
}> = (props) => {
  const {
    variant = "standard",
    accessoryTray,
    compact,
    hideCTA,
    className,
    preserveHeight,
    ...others
  } = props;
  const imageHeight = variant === "platinum" ? 120 : compact ? 32 : 48;
  return (
    <div
      className={classnames(
        "flex flex-col items-start justify-between w-full h-full rounded overflow-hidden bg-white shadow-low p-lg",
        className,
      )}
      {...others}
    >
      {variant !== "info" ? (
        <>
          <div>
            <Placeholder
              width={variant === "platinum" ? "100%" : compact ? "32" : "48"}
              height={imageHeight.toString()}
              className="mb-md"
            >
              <Rect width="100%" height="100%" />
            </Placeholder>

            <div style={{ minHeight: preserveHeight ? 44 : 0 }}>
              <Placeholder width="100%" height="20">
                <Rect width="100%" height="100%" />
              </Placeholder>
            </div>

            <Placeholder width="120" height="10" className="mt-sm">
              <Rect width="100%" height="100%" />
            </Placeholder>

            <div className="flex mt-sm">
              <Placeholder width="66" height="20" className="mt-xs mr-xs">
                <Rect width="100%" height="100%" />
              </Placeholder>
              <Placeholder width="66" height="20" className="mt-xs">
                <Rect width="100%" height="100%" />
              </Placeholder>
            </div>

            <div className="mt-sm">
              <Placeholder width="70%" height="13">
                <Rect width="100%" height="100%" />
              </Placeholder>
              <Placeholder width="40%" height="13" className="mt-sm">
                <Rect width="100%" height="100%" />
              </Placeholder>
            </div>
          </div>
          {accessoryTray && (
            <div className="border-light-grey border-t mt-lg pt-md w-full">
              <Placeholder width="80" height="14">
                <Rect width="100%" height="100%" />
              </Placeholder>
              <div className="flex justify-between mt-sm">
                <Placeholder width="28%" height="14">
                  <Rect width="100%" height="100%" />
                </Placeholder>
                <Placeholder width="39%" height="14">
                  <Rect width="100%" height="100%" />
                </Placeholder>
                <Placeholder width="28%" height="14">
                  <Rect width="100%" height="100%" />
                </Placeholder>
              </div>
            </div>
          )}

          {!accessoryTray && !hideCTA && (
            <div className="w-full mt-lg">
              <Placeholder width="100%" height="36" className="mt-xs">
                <Rect y="0" rx="16" ry="16" width="100%" height="100%" />
              </Placeholder>
            </div>
          )}
        </>
      ) : (
        <div className="w-full">
          <Placeholder width="100%" height="18" className="mt-xs">
            <Rect width="100%" height="100%" />
          </Placeholder>
          <div className="mt-md">
            <Placeholder width="70%" height="13">
              <Rect width="100%" height="100%" />
            </Placeholder>
            <Placeholder width="40%" height="13" className="mt-xs">
              <Rect width="100%" height="100%" />
            </Placeholder>
          </div>
        </div>
      )}
    </div>
  );
};

export default DispensaryCardPlaceholder;
