import { AxiosRequestConfig } from "axios";

import consumerApi from "api/services/consumer-api";
import { DispensariesV3Response } from "custom-types/DispensariesV3";
import logError from "utils/logError";

interface Params {
  // sort:
  sort?: string;

  // pagination:
  skip?: number;
  take?: number;

  // lat / lon:
  lat?: number;
  lon?: number;
  radius?: string | number; // tolerate both "30mi" and 30

  // bounding box:
  topLat?: number;
  leftLon?: number;
  bottomLat?: number;
  rightLon?: number;

  // if true, don't filter by geo -- only used to calc distances
  disableGeoFilter?: boolean;

  // if true, only returns stores that have the lat/lon in a delivery service area
  deliversToPoint?: boolean;

  // results matching this merch unit will be in sponsoredStores result:
  merchUnitName?: string;

  // menu filtering:
  strainName?: string[];
  productCategory?: string[];

  // dispensary id filter:
  ids?: number[];

  // other dispensary properties:
  flags?: string[];
}

const getDispensariesV3 = async (
  params: Params = {},
): Promise<DispensariesV3Response> => {
  try {
    const response = await consumerApi
      .get(
        "/api/dispensaries/v3",
        {
          "axios-retry": {
            retries: 0,
          },
          params: params,
          timeout: 10000,
        } as AxiosRequestConfig, // axios-retry extends this to allow the 'axios-retry' param
      )
      .then((response) => response.data);

    return response;
  } catch (e) {
    logError(e.message, {
      functionName: "getDispensariesV3",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return {
      currentPage: 1,
      pageCount: 1,
      sponsoredStores: [],
      stores: [],
      storesCount: 0,
    };
  }
};

export default getDispensariesV3;
