import React from "react";
import classnames from "classnames";

import OfferIcon from "components/Icons/deals-tags.svg";

import OfferProgress from "./OfferProgress";

interface Props {
  label: string;
  textClassName?: string;
  progress?: number;
  onClickAll?: () => void;
  buttonRef?: React.MutableRefObject<null>;
}

const OfferLabel: React.FC<Props> = ({
  label,
  textClassName = "text-notification",
  progress,
  onClickAll,
  buttonRef,
}) => {
  const showProgress = !!progress && progress > 0;

  return (
    <div className="flex flex-row items-center text-xs font-bold justify-between">
      <span className="flex">
        <OfferIcon
          width="20"
          height="20"
          className="flex-shrink-0 text-white bg-notification rounded p-[4px]"
        />
        <span
          data-testid="offer-label__label"
          className={classnames("ml-sm", textClassName)}
        >
          {label}
        </span>
      </span>
      {showProgress && <OfferProgress progress={progress} />}
      {onClickAll && (
        <button
          onClick={onClickAll}
          ref={buttonRef}
          className="underline text-xs font-bold"
        >
          see all
        </button>
      )}
    </div>
  );
};

export default OfferLabel;
